import { ERubricMap } from "@betnbet/front-sdk/dist/Articles/article-config"
import { TBreadCrumbsItemProps } from "./App/breadcrumbs"
import { TSelectOption } from "./common/select"
import { TLang, TLng } from "./lang"
import { TWidgetConfig } from "./store/store"

// DB SDK
type Q<T> = (signal?: AbortSignal | null) => Promise<T>
type QV<T, V> = (signal: AbortSignal | null, vars: V) => Promise<T>
type QVN<T, V> = (signal: AbortSignal | null, vars: V) => Promise<T | null>

export type TRouteResponse<T> = {
  type: "RouteResponse"
  data?: T
  widget?: TWidgetConfig
  statusCode?: number
}

export type TSsrState = {
  data: any
  status?: number | undefined
  region: TWebRegion
  region_list: TWebRegionListItem[]
  widget: TWidgetConfig | undefined
  user_country_iso: string
  lang: TLang
  lng: TLng
}

// LAYOUT
export type TLayoutBk = {
  name: string
  slug: string
  rating: number
  logo_url: string
}
export type TLayoutTournament = {
  slug: string
  name: string
  logo_url: string
  sport_slug: string
}

export type TLayoutBonus = {
  link: string
  text: string
  pixel: string | null
  bookmaker_logo: string
  bookmaker_name: string
}

export type TBonusInHeader = {
  image_url: string | null
  image_alt: string
  abbreviation: string
  slug: string
}

export type TTipInHeader = {
  team1: string
  team2: string
  logo1: string
  logo2: string
  slug: string
}

export type TBlogInHeader = {
  image_url: string | null
  image_alt: string
  slug: string
  abbreviation: string
}

export type TLayout = {
  top3Bk: TLayoutBk[]
  header_nav: TNavigation_item[]
  footer_nav: TNavigation_item[]
  bonuses: TLayoutBonus[]
  footer_articles: TNavigation_item[]
  footer_tips: TNavigation_item[]
  seo_title: string
  seo_description: string
  footer_text: string
  bonus_in_header: TBonusInHeader | null
  tips_in_header: TTipInHeader[]
  blogs_in_header: TBlogInHeader[]
  main_widget: TWidgetData | null
}

export type TLayout_load = Q<TLayout>
export type TLayout_res = TRouteResponse<TLayout>

// SEARCH
export type TSearchResult_bk = {
  slug: string
  name: string
  link: string | null
  url: string
  logo_url: string
}

export type TSearchResult_bonus = {
  slug: string
  name: string
}

export type TSearchResult_article = {
  slug: string
  name: string
  image_url: string | null
  publish_date: string | null | Date
}

export type TSearchModel_bk = {
  type: "bookmaker"
  bookmakers: TSearchResult_bk[]
  bonuses: TSearchResult_bonus[]
  articles: TSearchResult_article[]
}
export type TSearchModel_team = {
  type: "team"
  teams: {
    name: string
    slug: string
    logo_url: string | null
    site_link: string | null
  }[]
  tips: {
    name: string
    slug: string
    team_left_logo: string | null
    team_right_logo: string | null
  }[]
  news: TSearchResult_article[]
}
export type TSearchModel_term = {
  type: "term"
  articles: TSearchResult_article[]
  term: {
    definition: string
    name: string
    slug: string
  }
}
export type TSearchModel_default = {
  type: "default"
  bookmakers: TSearchResult_bk[]
  bonuses: TSearchResult_bonus[]
  articles: TSearchResult_article[]
}
export type TSearch =
  | TSearchModel_bk
  | TSearchModel_team
  | TSearchModel_term
  | TSearchModel_default
export type TSearch_vars = {
  text?: string
}
export type TSearch_load = QV<TSearch, TSearch_vars>

// ACADEMY
export enum EArticleSection {
  strategy = "strategy",
  bet = "bet",
  sport = "sport",
  important = "important",
}
export type TPageAcademyItem = {
  slug: string
  rubric: number | null
  time_required: string
  abbreviation: string
}
export type TPageAcademy = Record<EArticleSection, TPageAcademyItem[]>
export type TPageAcademy_res = TRouteResponse<TPageAcademy>
export type TPageAcademy_load = Q<TPageAcademy>

//RATING
export type TRating = {
  id: string
  slug: string
  type: EBookmakerBrandType
  meta_title: string
  meta_description: string
  h1: string
  description: string
  short_name: string
  image_url: string
  region_id: string
  active: boolean
  content: string
  bks: TRatingBk[]
}
export type TRatingBk = {
  slug: string
  type: EBookmakerBrandType
  logo_url: string
  link: string | null
  rating: string
  bonus_name: string | null
  name: string
  main_page_button: boolean
  reviews: number
  is_shown: boolean
}
export type TRatingsPage_item = {
  id: string
  slug: string
  type: EBookmakerBrandType
  h1: string
  short_name: string
  image_url: string
  region_id: string
  homepage_icon: string | null
}
export type TRatingsPage = {
  rating: TRating
  ratings: TRatingsPage_item[]
}

export type TRatingsPage_vars = {
  slug: string
  type: EBookmakerBrandType
}

export type TRatingsPage_load = Q<TRatingsPage>
export type TRatingsPage_res = TRouteResponse<TRatingsPage>

// TIP
export type TArticleTipBk = {
  logo_url: string
  slug: string
  link: string | null
  link_tips: string | null
  url: string
}

export type TArticleTip = {
  tour: string
  date_time: any
  week_day: string
  mounth_day: string
  time: string
  market: string
  coefficient: string
  bookmaker: TArticleTipBk
  sport_name: string
  sport_slug: string
  sport_label_color: string
  tournament_name: string
  team1_name: string
  team1_logo_url: string | null
  team1_slug: string | null
  team2_name: string
  team2_logo_url: string | null
  team2_slug: string | null
  article_name: string
  article_slug: string
  article_id: string
  timeLeft: string
  ligaStavokWidget: TWidgetData | null
}

export type TArticleTip_card = {
  date_time: any
  coefficient: string
  market: string
  bookmaker_logo_url: string
  bookmaker_slug: string
  sport_label_color: string
  sport_name: string
  sport_slug: string
  team1_logo_url: string | null
  team1_name: string
  team2_logo_url: string | null
  team2_name: string
  article_name: string
  article_slug: string
}

export type TArticlesTipCards_vars = {
  exclude_tip_slug?: string
  sport_slug?: string
  tournament_slug?: string
  take?: number
  skip?: number
  user_id?: string | null
}

export type TArticleTipCards_load = QV<
  TArticleTip_card[],
  TArticlesTipCards_vars
>

export type TTipMiniCard = {
  slug: string
  lead: string
  date_time: string | null
  team1_logo_url: string
  team1_name: string
  team2_logo_url: string
  team2_name: string
  sport_slug: string
}

export type TTipMiniCardSport = {
  slug: string
  name: string
}

export type TTipMiniCards = {
  sport: TTipMiniCardSport
  cards: TTipMiniCard[]
}

export type TPageArticleTip = TPageArticle_item & {
  web_tips: TArticleTip_card[]
  tip: TArticleTip | null
  reactions: TArticleReactions
}

export type TPageArticleTip_vars = {
  slug: string
  disableCache?: boolean
}

export type TPageArticleTip_load = QVN<TPageArticleTip, TPageArticleTip_vars>
export type TPageArticleTip_res = TRouteResponse<TPageArticleTip>

export type TTipFilterSport = {
  slug: string
  meta_title: string
  meta_description: string
  h1: string
  name: string
  content: string | null
}

export type TTipFilterItem = {
  slug: string
  name: string
}

export type TTipFilterTournament = {
  slug: string
  meta_title: string
  meta_description: string
  h1: string
  name: string
  content: string | null
}

export type TTipFilterList = {
  sport: TTipFilterSport | null
  tournaments: TTipFilterItem[]
  tournament: TTipFilterTournament | null
  tips_count: number
  sports: TTipFilterItem[]
  page: number
} & TPagination_load<TArticleTip_card>

export type TTipFilterList_vars = {
  sport_slug?: string
  tournament_slug?: string
  user_id?: string | null
} & TPagination_vars

export type TTipFilterList_load = QV<TTipFilterList, TTipFilterList_vars>
export type TTipFilterListPage_res = TRouteResponse<TTipFilterList>

// BONUS
export type TArticleBonus_card = {
  slug: string
  image_url: string | null
  image_alt: string
  bookmaker_slug: string
  bookmaker_logo: string
  bookmaker_closed: boolean
  toBk: string
  toBonus: string
  title: string
  time_left: string | null
  is_exclusive: boolean
  end_date: any | null
  name: string
}

export type TArticleBonusCards = TPagination_load<TArticleBonus_card> & {
  all_count: number
}

export type TArticleBonusCards_vars = {
  parent_article_slug?: string
  is_top?: boolean
  bk_slug?: string
  category_slug?: string
} & TPagination_vars

export type TArticleBunusCards_load = QV<
  TArticleBonusCards,
  TArticleBonusCards_vars
>

export type TPageArticleBonus = TPageArticle_item & {
  bonuses: TArticleBonus_card[]
  bookmaker_logo: string
  is_finished: boolean
  promocode: string | null
  reactions: TArticleReactions
}

export type TPageArticleBonus_vars = {
  slug: string
  disableCache?: boolean
}

export type TPageArticleBonus_res = TRouteResponse<TPageArticleBonus>
export type TPageArticleBonus_load = QVN<
  TPageArticleBonus,
  TPageArticleBonus_vars
>

export type TBonusListPage = TArticleBonusCards & {
  top_cards: TArticleBonus_card[]
  page: number
  meta_title: string
  meta_description: string
  h1: string
  description: string
  breadcrumbs: TBreadCrumbsItemProps[]
  bks: TSelectOption[]
  bk: TSelectOption
  categories: TSelectOption[]
  category: TSelectOption
  seo_text?: string
}
export type TBonusListPage_res = TRouteResponse<TBonusListPage>
export type TBonusListPage_vars = { bk_or_category_slug?: string }

// BLOG
export type TArticleBlog_miniCard = {
  slug: string
  name: string
  created_at: string
  time_required: string
  avatar: string
  avatar_alt: string
}

export type TArticleBlogMiniCards_vars = {
  take: number
}

export type TArticleBlogMiniCards_load = QV<
  TArticleBlog_miniCard[],
  TArticleBlogMiniCards_vars
>

export type TPageArticleBlog_vars = {
  slug: string
}
export type TPageArticleBlog_res = TRouteResponse<TArticlePage>
export type TPageArticleBlog_load = QVN<TArticlePage, TPageArticleBlog_vars>

// ARTICLE
export type TArticle_card = {
  date: string
  relative_date: string
  is_relative_date: boolean
  slug: string
  name: string
  title: string
  description: string
  image_alt: string
  image_url: string | null
  bk_logo: string | null
  rubric: number | null
  time_required: string
  author_first_name: string
  author_last_name: string
  author_avatar: string
}
export type TArticlesCards_vars = TPagination_vars & {
  bookmaker_slug?: string | null
  team_slug?: string
  rubric?: number
  exclude_article_slug?: string | undefined
  user_id?: string
}
export type TArticlesCards = TPagination_load<TArticle_card> & {
  all_count: number
}
export type TArticlesCards_load = QV<TArticlesCards, TArticlesCards_vars>

export type TArticlesCardsPage = TArticlesCards & {
  rubricId: number
  page: number
}
export type TArticlesCardsPage_res = TRouteResponse<TArticlesCardsPage>
export type TArticlesCardsPage_load = QV<TArticlesCards, TArticlesCards_vars>

export type TPageArticle_meta = {
  id: string
  date: string
  date_iso: string
  updated_at: string
  updated_at_iso: string
  slug: string
  abbreviation: string
  time_required: string
  name: string
  title: string
  description: string
  image_url: string | null
  image_alt: string
  rubric: number | null
  lead: string
  author_question: string
  author_id: string
  author_name: string
  author_avatar: string
  author_path: string
  author_about: string | null
  author_vk: string | null
  author_fb: string | null
  author_tw: string | null
  author_tg: string | null
  author_inst: string | null
  author_site: string | null
  author_following: boolean
  regions: { hreflang: string; domain: string }[]
  comments: TComment[]
  headings: THeadings | null
  tags: TTags
}
export type TPageArticle_item = TPageArticle_meta & {
  content: string
  bookmaker_name?: string | null
  bookmaker_slug?: string | null
  bookmaker_goto?: string | null
  bookmaker_rating?: number | null
  bookmaker_logo_url?: string | null
  bookmaker_bonus?: string | null
  bookmaker_alternative_link?: string | null
}
export type TPageArticle_item_vars = {
  slug: string
  rubric: number
}
export type TPageArticle_item_load = QVN<
  TPageArticle_item,
  TPageArticle_item_vars
>
export type TArticleTip_vars = {
  slug: string
}

export type TPageArticleTip_item_load = QVN<TArticleTip, TArticleTip_vars>

export type TArticle = TPageArticle_item & {
  web_articles: TArticle_card[]
  comments: TComment[]
}

export type TPageArticle_vars = {
  slug: string
  count: number
  rubric: number
  disableCache?: boolean
}

export type TPageArticle_load = QVN<TArticle, TPageArticle_vars>

export type TArticlePage = {
  article: TPageArticle_item
  rubricId: ERubricMap
  breadcrumbTitle: string
  topicArticles: TArticle_card[]
  reactions: TArticleReactions
}
export type TArticlePage_load = QV<TArticlePage, TPageArticle_vars>
export type TArticlePage_res = TRouteResponse<TArticlePage>

// GLOSSARY
export type TGlossaryList_termItem = {
  name: string
  slug: string
}

export type TGlossaryList_terms = [string, TGlossaryList_termItem[]]

export type TGlossaryList = { glossary: TGlossaryList_terms[] }

export type TGlossaryList_load = Q<TGlossaryList>
export type TGlossaryList_res = TRouteResponse<TGlossaryList>

export type TGlossary = {
  glossary: TPageArticle_item & {
    term_definition: string
    cards: TArticle_card[]
    reactions: TArticleReactions
  }
}

export type TGlossary_vars = {
  slug: string
}

export type TGlossary_load = QVN<TGlossary, TGlossary_vars>
export type TGlossary_res = TRouteResponse<TGlossary>

// COMMENT
export enum ECommentTypes {
  forecast = "forecast",
  article = "article",
  bookmaker = "bookmaker",
  competition = "competition",
  quest = "quest",
}

export type TComment = {
  id: string
  parent_id: string | null
  content: string | null
  created_at: string
  author_id: string
  author_last_name: string
  author_first_name: string
  author_nickname: string
  author_avatar: string | null
  author_path: string
  comments?: TComment[]
}

export type TComments_vars = {
  base_type: ECommentTypes
  disableCache?: boolean
}
export type TComments_load = QV<TComment[], TComments_vars>

export type TCommentsBySlug_load = QV<
  TComment[],
  TPageArticle_item_vars & { disableCache?: boolean }
>

export type TCreateComment_vars = {
  base_type: ECommentTypes
  article_id?: string
  parent_id?: string | null
  content: string
}

export type TCreateComment_load = QV<TComment, TCreateComment_vars>

export type TDeleteComment_vars = { comment_id: string }

export type TDeleteComment_load = QV<boolean, TDeleteComment_vars>

// REDIRECT
export type TRedirect = {
  req_url: string
  dest_url: string
}
export type TRedirect_load = Q<TRedirect[]>

// TOURNAMENT
export type TTournament_card = {
  slug: string
  name: string
  image_url: string
  status: string
}

export type TTournamentCards_vars = {
  take: number
}

export type TTournamentCards_load = QV<
  TTournament_card[],
  TTournamentCards_vars
>

export type TTournamentData = {
  slug: string
  name: string
  image_url: string
  status_text: string
  time_left: string | null
  terms: string
  pass_end_date_time: any
  questions_count: number
  user_count: number
  avatars: string[]
}

export type TTournamentData_vars = { slug: string }

export type TTournamentAnswer = {
  question_id: string
  question: string
  answer: string
  correct_answer: string | null
}

export type TTournamentAnswer_vars = { slug: string }

export type TTournamentLiderboad_item = {
  idx: number
  nickname: string
  points: number
  reward: string | null
  is_me: boolean
}

export type TTournamentLiderboad_vars = { slug: string }

export type TTournamentBroadcast_answer = {
  text: string
  stats: string
}

export type TTournamentBroadcast_question = {
  id: string
  idx: number
  text: string
  points: number
  QuestionAnswer: TTournamentBroadcast_answer[]
}

export type TTournamentBroadcast_item = {
  match_minute: number
  text: string
  image_url: string | null
  Question: TTournamentBroadcast_question | null
}

export type TTournamentBroadcast_vars = { slug: string }

export type TTournament = {
  data: TTournamentData | null
  answers: TTournamentAnswer[]
  liderboard: TTournamentLiderboad_item[]
  broadcast: TTournamentBroadcast_item[]
}

export type TTournament_vars = { slug: string }

export type TTournament_load = QV<TTournament, TTournament_vars>

export type TTournamentQuestion_answer = {
  id: string
  text: string
  image_url: string | null
}

export type TTournamentQuestion = {
  id: string
  text: string
  image_url: string | null
  QuestionAnswer: TTournamentQuestion_answer[]
}

export type TTournamentQuestion_vars = {
  slug: string
  skip: number
}

export type TTournamentQuestion_load = QVN<
  TTournamentQuestion,
  TTournamentQuestion_vars
>

export type TTournamentSaveAnswer_vars = {
  answer_id: string
  question_id: string
  tournament_slug: string
}

export type TTournamentSaveAnswer_load = QV<boolean, TTournamentSaveAnswer_vars>

// BOOKMAKER
export type TTopBk_item = {
  slug: string
  logo_url: string
  link: string | null
  rating: number
  bonus_name: string | null
  name: string
  main_page_button: boolean
  main_page_hidden: boolean
  main_page_like: boolean
}

export type TTopBkList_vars = {
  take: number
  closed?: string | null
}

export type TBookmakerListPage_item = TTopBk_item & {
  // slug: string
  // rating: number
  // link: string | null
  // logo_url: string
  // name: string
  // url: string
  // pros: string[]
  // cons: string[]
  // related_links: {
  //   title: string
  //   url: string
  // }[]
}

export type TBookmakerListPage = {
  bookmakers: TBookmakerListPage_item[]
}

export type TBookmakerListPage_load = Q<TBookmakerListPage>
export type TBookmakerListPage_res = TRouteResponse<TBookmakerListPage>

// BOOKMAKER REVIEW
export type TBkReviewInfo = {
  slug: string
  name: string
  url: string
  link: string | null
  logo_url: string
  support_email: string | null
  my_review_id: string | null
  reviews_count: number
  mean_rating: number
  one: number
  two: number
  three: number
  four: number
  five: number
}

export type TBkReviewInfo_vars = { slug: string }

export type TBkReviewInfo_load = QVN<TBkReviewInfo, TBkReviewInfo_vars>

export type TBkReviewItemReply = {
  created_at: string
  text: string
  user_avatar: string | null
  user_path: string
}

export type TBkReviewItem = {
  id: string
  created_at: string
  stars: string
  title: string
  text: string
  like_count: number
  i_like_it: boolean
  user_avatar: string | null
  user_name: string
  user_path: string
  bookmaker_logo: string
  bookmaker_slug: string
  bookmaker_name: string
  Reply: TBkReviewItemReply | null
}

export type TBkReviewItems = TPagination_load<TBkReviewItem> & {
  all_count: number
}

export type TBkReviewItems_vars = TPagination_vars & {
  slug?: string
  sortByRating?: boolean
}

export type TBkReviewItems_load = QV<TBkReviewItems, TBkReviewItems_vars>

export type TBkReviewPage = {
  info: TBkReviewInfo | null
  items: TBkReviewItem[]
}

export type TBkReviewPage_vars = { slug: string }

export type TBkReviewPage_load = QV<TBkReviewPage, TBkReviewPage_vars>
export type TBkReviewPage_res = TRouteResponse<TBkReviewPage>

export type TAllReviewPage = TBkReviewItems & {
  bks: TSelectOption[]
  page: number
}

export type TAllReviewPage_load = Q<TAllReviewPage>
export type TAllReviewPage_res = TRouteResponse<TAllReviewPage>

export type TCreateReview_vars = {
  bk_slug: string
  stars: number
  title: string
  text: string
  notify_bk: boolean
}

export type TCreateReview_load = QVN<TBkReviewItem, TCreateReview_vars>

export type TCreateReviewReply_vars = {
  review_id: string
  text: string
  bkSlug: string
}

export type TCreateReviewReply_load = QVN<
  TBkReviewItemReply,
  TCreateReviewReply_vars
>

export type TLikeReview_vars = {
  review_id: string
  bkSlug: string
}
export type TLikeReview_load = QV<boolean, TLikeReview_vars>

export type TBksForReviewSelect = {
  select: TSelectOption[]
}
export type TBksForReviewSelect_load = Q<TBksForReviewSelect>

// LOCAL BUSINESS
export type TLoacalBusinessListPage_item = {
  slug: string
  rating: number
  smoking_room: boolean
  non_smoking_room: boolean
  vip_room: boolean
  wifi: boolean
  kitchen: boolean
  bar: boolean
  country: string
  region: string
  street: string
  images: string[]
  bookmaker_id: string
  bookmaker_slug: string
  bookmaker_logo_url: string | null
}

export type TLocalBusinessList = {
  list: TLoacalBusinessListPage_item[]
  count: number
  bookmakers: { slug: string; name: string }[]
}

export type TLocalBusinessListPage_vars = {
  skip: number
  take: number
  bookmaker_slug?: string | null
}

export type TLocalBusinessListPage_res = TRouteResponse<TLocalBusinessList>
export type TLocalBusinessListPage_load = QV<
  TLocalBusinessList,
  TLocalBusinessListPage_vars
>

export type TLocalBusinessPage = {
  slug: string
  longitude: number
  latitude: number
  country: string
  region: string
  street: string
  postal_code: number
  locality: string
  smoking_room: boolean
  non_smoking_room: boolean
  vip_room: boolean
  wifi: boolean
  kitchen: boolean
  bar: boolean
  rating: number
  service_rating: number
  interior_rating: number
  location_rating: number
  atmosphere_rating: number
  working_hours: string
  is_open: boolean
  monday_open: string
  monday_closed: string
  tuesday_open: string
  tuesday_closed: string
  wednesday_open: string
  wednesday_closed: string
  thursday_open: string
  thursday_closed: string
  friday_open: string
  friday_closed: string
  saturday_open: string
  saturday_closed: string
  sunday_open: string
  sunday_closed: string
  review: string
  phones: string[]
  images: string[]
  bookmaker_name: string
  bookmaker_link: string
  bookmaker_url: string
  bookmaker_slug: string
  bookmaker_logo_url: string | null
}

export type TLocalBusinessPage_vars = {
  slug: string
}

export type TLocalBusinessPage_load = QVN<
  TLocalBusinessPage,
  TLocalBusinessPage_vars
>
export type TLocalBusinessPage_res = TRouteResponse<TLocalBusinessPage>

export type TBookmakerPage_bookmaker = {
  id: string
  slug: string
  type: EBookmakerBrandType
  name: string
  title: string | null
  url: string
  content: string
  logo_url: string
  image_url: string
  image_url_2: string
  rating: number
  description: string | null
  link: string | null
  heading: string | null
  bonus_name: string | null
  support_phone: string | null
  support_email: string | null
  legal_name: string | null
  trade_name: string | null
  founded: string | null
  closed: string | null
  founders: string | null
  headquarters: string | null
  ceo: string | null
  itn: string | null
  psrn: string | null
  iec: string | null
  russia_fts_license: string | null
  betnbet_review: string | null
  lead: string | null
  active: boolean
  cons: string[]
  pros: string[]
  user_rating: number
  payments: TBkPayment[]
}

export type TBkPayment = {
  logo_url: string
  logo_name: string
  currency: string
  min_deposit: number | null
  max_deposit: number | null
  deposit_time: string | null
  deposit_comission: string | null
  min_cashout: number | null
  max_cashout: number | null
  cashout_time: string | null
  cashout_comission: string | null
}

export type TBookmakerPage_article = {
  slug: string
  abbreviation: string
}

export type TBookmakerPage = {
  bookmaker: TBookmakerPage_bookmaker
  type: EBookmakerBrandType
  articles: TBookmakerPage_article[]
  reviews: TBkReviewItem[]
  bonuses: TArticleBonus_card[]
}

export type TBookmakerPage_vars = {
  slug: string
  type: EBookmakerBrandType
}

export type TBookmakerPage_load = QVN<TBookmakerPage, TBookmakerPage_vars>
export type TBookmakerPage_res = TRouteResponse<TBookmakerPage>

// HOME
export type THomePage = {
  bookmakers: TTopBk_item[]
  articles: TArticle_card[]
  news: TArticle_card[]
  tips: TArticleTip_card[]
  bonuses: TArticleBonus_card[]
  tournaments: TTournament_card[]
  quests: any[]
}

export type THomePage_vars = {
  skip_articles: number
  take_articles: number
}

export type THomePage_load = QV<THomePage, THomePage_vars>

export type THomeNewPage = {
  tips: TTipMiniCard[]
  bookmakers: TTopBk_item[]
  blogs: TArticleBlog_miniCard[]
  ratings: TRatingsPage_item[]
  bonus_categories: {
    name: string
    slug: string
    homepage_text: string | null
    homepage_image: string | null
  }[]
  bonuses: TArticleBonusCards
  feed: TNewsFeed_data
  features: TFeature[]
  widget_feed: TWidgetData | null
  widget_side: TWidgetData | null
}

export type THomeNewPage_res = TRouteResponse<THomeNewPage>
export type THomeNewPage_load = Q<THomeNewPage>

// FEED QUESTION

export type TFeedAnswerResult = TNewsFeed_question | null

export type TFeedAnswerResult_vars = {
  answer_id: string
  question_id: string
}

export type TFeedAnswerResult_load = QV<
  TFeedAnswerResult,
  TFeedAnswerResult_vars
>
// FEED
export type TNewsFeed_news = {
  id: string
  slug: string
  image_url: string
  image_alt: string
  name: string
  relative_date: string
  is_relative_date: boolean
  comments_count: number
  reactions_sum: number
  my_reaction: EArticleReaction | undefined
  top3_reactions: EArticleReaction[]
}

export type TNewsFeedMatch_tip = {
  author_avatar: string
  slug: string
  lead: string
}

export type TNewsFeed_match = {
  id: string
  team1_name: string
  team1_image: string
  team2_name: string
  team2_image: string
  date: string
  time: string
  tip_slug: string | null
  is_finished: boolean
  team1_score: number | null
  team2_score: number | null
  sport_slug: string
}

export type TNewsFeed_question = {
  id: string
  text: string
  count: number
  answers: TNewsFeed_answer[]
}

export type TNewsFeed_answer = {
  id: string
  text: string
  count?: number
  is_myAnswer: boolean
}

export type TNewsFeedMatch = {
  type: "match"
  id: number
  news: TNewsFeed_news[]
  match: TNewsFeed_match
  question: TNewsFeed_question | null
}
export type TNewsFeedNews = {
  type: "news"
  id: number
  news: TNewsFeed_news
}

export type TNewsFeed = TNewsFeedMatch | TNewsFeedNews

export type TNewsFeed_data = {
  feed: TNewsFeed[]
  count_all: number
  sports: { label: string; svg_name: any; value?: string }[]
}

export type TNewsFeed_vars = {
  take: number
  skip: number
  sport_slug?: string
}

export type TNewsFeed_load = QV<TNewsFeed_data, TNewsFeed_vars>

// FEATURE
export type TFeature = {
  title: string
  description: string | null
  image_url: string
  link_url: string
  link_name: string
}

export type TFeature_vars = {
  take: number
}

export type TFeature_load = QV<TFeature[], TFeature_vars>

// Question
export type TQuestion = {
  id: string
  text: string
  points: number
  image_url: string | null
  QuestionAnswer: TQuestionAnswer[]
}

export type TQuestionAnswer = {
  id: string
  text: string
  image_url: string | null
}

export type TQuestion_vars = {
  quiz_slug?: string
  tournament_slug?: string
  skip: number
}

export type TQuestion_load = QVN<TQuestion, TQuestion_vars>

export type TAnswerResult = {
  is_correct: boolean
  description: string | null
}

export type TAnswerResult_vars = {
  answer_id: string
}

export type TAnswerResult_load = QV<TAnswerResult, TAnswerResult_vars>

// QUIZ
export type TQuiz = {
  question_count: number
  slug: string
}

export type TQuizPage_vars = {
  slug: string
}

export type TQuiz_load = QVN<TQuiz, TQuizPage_vars>

export type TQuizResult = {
  title: string | null
  text: string | null
  image_url: string | null
  link: string | null
}

export type TQuizResult_vars = {
  quiz_slug: string
  ponts: number
}

export type TQuizResult_load = QVN<TQuizResult, TQuizResult_vars>

export type TSaveQuizAnswer = { id: string }

export type TSaveQuizAnswer_vars = {
  user_quiz_id?: string | null
  quiz_slug: string
  question_id: string
  answer_id: string
}

export type TSaveQuizAnswer_load = QVN<TSaveQuizAnswer, TSaveQuizAnswer_vars>

export type TQuizPage = {
  quiz: TQuiz
  question: TQuestion
  skip: number
}

export type TQuizPage_res = TRouteResponse<TQuizPage>
export type TQuizPage_load = QV<TQuizPage, TQuizPage_vars>

// NAVIGATION
export type TNavigation_item = {
  text_link: string
  text_link_additional: string | null
  url_link: string
  image_url: string | null
  Children: Omit<TNavigation_item, "Children">[]
}

export type TNavigation_vars = {
  slug: string
}

export type TNavigation_load = QV<TNavigation_item[], TNavigation_vars>

// TEAM
export type TTeamInfo = {
  slug: string
  name: string
  logo_url: string | null
  founded_at: number | null
  coach_name: string | null
  stadium: string | null
  site_link: string | null
  twitter_link: string | null
  instagram_link: string | null
  facebook_link: string | null
  vk_link: string | null
  youtube_link: string | null
  telegram_link: string | null
  seo_title: string | null
  seo_description: string | null
  country_name: string | null
  country_image: string | null
  full_name: string
}

export type TTeamInfo_vars = { slug: string }

export type TTeamDescription_vars = { slug: string }

export type TTeamNextTip = TArticleTip_card & { article_description: string }

export type TTeamNextTip_vars = { slug: string }

export type TTeamNews_item = {
  image_url: string | null
  alt: string | null
  name: string
  date: string
  slug: string
}

export type TTeamSquad_item = {
  number: number | null
  full_name: string
  weight_kilo: number | null
  height_cm: number | null
  age: number | null
  role: string | null
  country_name: string | null
  country_image: string | null
}

export type TTeamSquad_vars = { slug: string }

export type TTeamPage = {
  team: TTeamInfo | null
  description: string | null
  tip: TTeamNextTip | null
  news: TArticlesCards
}

export type TTeamPage_vars = { slug: string }

export type TTeamPage_load = QV<TTeamPage, TTeamPage_vars>
export type TTeamPage_res = TRouteResponse<TTeamPage>

export type TTeamSquadPage = {
  team: TTeamInfo | null
  squad: TTeamSquad_item[]
}

export type TTeamSquadPage_vars = { slug: string }

export type TTeamSquadPage_load = QV<TTeamSquadPage, TTeamSquadPage_vars>
export type TTeamSquadPage_res = TRouteResponse<TTeamSquadPage>

// ME
export type TMeData = {
  id: string
  php_id: number
  email: string
  nickname: string
  first_name: string
  last_name: string
  job: string
  about_me: string
  avatar: string
  bookmaker: string | null
  social_fb: string
  social_vk: string
  social_tw: string
  social_tg: string
  social_ins: string
  social_site: string
}

export interface TMe {
  data: TMeData
  notifications: TMeNotification[]
  nonReadableNotification: number
}

export type TMe_load = Q<TMe>

export type TUploadAvatar_vars = { avatar: any }

export type TUploadAvatar_load = QV<{ avatar: string }, TUploadAvatar_vars>

// NOTIFICATIONS
export enum ENotificationTypes {
  comment = "comment",
  answer = "answer",
  bonus = "bonus",
  announcement = "announcement",
}

export type TNotificationCommentCreate = {
  type: ENotificationTypes.comment
  article_title: string
  article_url: string
  author_id: string
}

export type TNotificationAnswerCreate = {
  type: ENotificationTypes.answer
  article_title: string
  article_url: string
  commentator_id: string
}

export type TNotificationCreate_vars =
  | TNotificationCommentCreate
  | TNotificationAnswerCreate

export type TMeNotificationComment = {
  id: string
  type: ENotificationTypes.comment
  created_at: string
  is_read: boolean
  article_title: string
  article_url: string
  author_nickname: string
  author_avatar: string | undefined
}

export type TMeNotificationAnswer = {
  id: string
  type: ENotificationTypes.answer
  created_at: string
  is_read: boolean
  article_title: string
  article_url: string
  commentator_nickname: string
  commentator_avatar: string | undefined
}

export type TMeNotification = TMeNotificationComment | TMeNotificationAnswer

export type TMeNotification_vars = {
  skip: number
  take: number
}

export type TNonReadableNotification_load = Q<number>

export type TTReadNotifications = {
  notifications: TMeNotification[]
  nonReadableNotification: number
}

export type TReadNotifications_load = QV<TTReadNotifications, string[]>

// ACCOUNT
export type TAccountAffiliatingBk = {
  slug: string
  name: string
  logo_url: string | null
  url: string
  link: string | null
  main_field_name: string
  secondary_field_name: string
  affiliation_text: string
}

export type TAcoountAffilationBk = {
  slug: string
  name: string
  logo_url: string | null
}

export type TAcoountAffilation = {
  id: string
  bookmaker: TAcoountAffilationBk
}

export type TAccountBkPage = {
  affiliatingBookmakers: TAccountAffiliatingBk[]
  affiliations: TAcoountAffilation[]
}

export type TAccountBkPage_load = Q<TAccountBkPage>

export type TAccountCreateAffiliation_vars = {
  bookmaker_slug: string
  data: { main_field: string; secondary_field: string }
}

export type TAccountCreateAffiliation_load = QV<
  { id: string },
  TAccountCreateAffiliation_vars
>

export type TAccountChangePassword_vars = {
  old_password: string
  new_password: string
}

export type TAccountChangePassword_load = QV<
  boolean,
  TAccountChangePassword_vars
>

export type TAccountUpdate_vars = {
  first_name: string
  last_name: string
  about_me: string
  nickname: string
  social_fb: string
  social_vk: string
  social_tw: string
  social_tg: string
  social_ins: string
  social_site: string
  avatar: string
}

export type TAccountUpdate_load = QV<TMeData, TAccountUpdate_vars>

export type TAccountLayout_res = TRouteResponse<undefined>
export type TAccountLayout_load = Q<undefined>

// REGION
export type TServerRegion = {
  id: string
  name: string
  domain: string
  ga_script: string
  ym_script: string
  seo_title: string
  seo_description: string
  footer_text: string
  telegram_link: string
  instagram_link: string
  youtube_link: string
  vk_link: string
  twitter_link: string
  facebook_link: string
  hreflang: string
  country: string
  country_iso_3166_1: string
  active: boolean
  indexnow_key: string | null
  adult_policy: number
  is_hidden: boolean
  homepage_ratings: boolean
  homepage_bonuses: boolean
  homepage_news: boolean
  homepage_bonuses_list: boolean
}

export type TWebRegion = {
  name: string
  domain: string
  telegram_link: string
  instagram_link: string
  youtube_link: string
  vk_link: string
  twitter_link: string
  facebook_link: string
  country_iso: string
  adult_policy: number
  homepage_ratings: boolean
  homepage_bonuses: boolean
  homepage_news: boolean
  homepage_bonuses_list: boolean
}

export type TWebRegionListItem = {
  domain: string
  country: string
  hreflang: string
  name: string
  country_iso: string
  is_hidden: boolean
}

// PAGINATION
export type TPagination_vars = {
  count: number
  page: number
}

export type TPagination_load<T> = {
  items: T[]
}

// WIDGET
export type TWidgetData = {
  image_url: string | null
  mob_image_url: string | null
  link: string | null
  mob_link: string | null
  pixel: string | null
  pixel_mob: string | null
  bk_slug?: string | null
  mark: string | null
  info: string | null
}

export type TWidgetCatfishData = {
  image_url: string | null
  mob_image_url: string | null
  link: string | null
  mob_link: string | null
  bookmaker_slug: string | null
  rotation: number
  pixel: string | null
  pixel_mob: string | null
  mark: string | null
  info: string | null
}

export type TWidgetCatfish_load = Q<TWidgetCatfishData[]>

export type TWidgetPopup = {
  popup: TWidgetPopupData | null
}

export type TWidgetPopupData = {
  image_url: string | null
  mob_image_url: string | null
  link: string | null
  mob_link: string | null
  bookmaker_slug: string | null
  pixel: string | null
  pixel_mob: string | null
}
export type TWidgetPopupData_vars = {
  bk_slug: string | null
}
export type TWidgetPopup_load = QV<TWidgetPopup, TWidgetPopupData_vars>

// SERVICE
export type TServicePage = TArticlePage
export type TServicePage_res = TRouteResponse<TServicePage>
export type TServicePage_load = Q<TServicePage>

// NOT FOUND
export type TNotFound_load = Q<undefined>
export type TNotFound_res = TRouteResponse<undefined>

// USER
export type TUserLayout = {
  id: string
  php_id: number
  name: string
  about: string
  ava: string
  vk: string | null
  fb: string | null
  inst: string | null
  tg: string | null
  tw: string | null
  web: string | null
  news_count: number
  blog_count: number
  tips_count: number
  comment_count: number
  is_me: boolean
  followers: string
  is_follower: boolean
}
export type TUserLayout_vars = { id: string } // php_id is number, but string as search param
export type TUserLayout_load = QV<TUserLayout, TUserLayout_vars>
export type TUserLayout_res = TRouteResponse<TUserLayout>

export type TUserArticlesPage = TArticlesCardsPage & {
  user_id: string
  meta_title: string
  meta_desc: string
}
export type TUserArticlesPage_res = TRouteResponse<TUserArticlesPage>
export type TUserArticlesPage_vars = TUserLayout_vars
export type TUserArticlesPage_load = QV<TUserArticlesPage, TUserLayout_vars>

export type TUserTipsPage = TTipFilterList & {
  user_id: string
  meta_title: string
  meta_desc: string
}
export type TUserTipsPage_res = TRouteResponse<TUserTipsPage>
export type TUserTipsPage_vars = TUserLayout_vars
export type TUserTipsPage_load = QV<TUserTipsPage, TUserTipsPage_vars>

export type TUserComment = {
  id: string
  article_name: string
  article_path: string
  author_name: string
  author_avatar?: string
  created_at: string
  content: string
}
export type TUserCommentsList = {
  count_all: number
} & TPagination_load<TUserComment>
export type TUserCommentsList_vars = TPagination_vars & { user_id: string }
export type TUserCommentsList_load = QV<
  TUserCommentsList,
  TUserCommentsList_vars
>

export type TUserCommentsPage = {
  user_id: string
  page: number
  meta_title: string
  meta_desc: string
} & TUserCommentsList
export type TUserCommentsPage_res = TRouteResponse<TUserCommentsPage>
export type TUserCommentsPage_vars = TUserLayout_vars
export type TUserCommentsPage_load = QV<
  TUserCommentsPage,
  TUserCommentsPage_vars
>

//USER FOLLOW

export type TFollowers = {
  followers: string
  is_follower: boolean
}
export type TFollowers_load = QV<TFollowers, TUserLayout_vars>

// REACTION

export enum EArticleReaction {
  like = "like",
  super = "super",
  wow = "wow",
  haha = "haha",
  interesting = "interesting",
  sad = "sad",
  angry = "angry",
}

export type TArticleReactionCollection = Partial<
  Record<EArticleReaction, number>
>

export type TArticleReactions = {
  reactions: Partial<Record<EArticleReaction, number>>
  sum: number
  my_reaction: EArticleReaction | undefined
}
export type TArticleCardReactions = {
  top3_reactions: EArticleReaction[]
  sum: number
  my_reaction: EArticleReaction | undefined
}
export type TArticleReactions_vars = { article_id: string }
export type TArticleReactions_load = QV<
  TArticleReactions,
  TArticleReactions_vars
>

export type TToggleReaction_vars = {
  article_id: string
  type: EArticleReaction
  prev_type: EArticleReaction | undefined
}
export type TToggleReaction_load = QV<TArticleReactions, TToggleReaction_vars>
export type TToggleCardReaction_load = QV<
  TArticleCardReactions,
  TArticleReactions_vars
>

// HEADINGS
export type THeading = {
  text: string
  ref: string
  index: number
}
export type THeadings = THeading[] | null

// TAGS
export type TTag = {
  link: string
  text: string
  img_path?: string
}
export type TTags = TTag[]

// BRAND TYPES
export enum EBookmakerBrandType {
  bk = "bk",
  casino = "casino",
}
