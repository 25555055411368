import { asyncComponent } from "@betnbet/front-sdk/dist/Common/Chunk"
import React from "react"
import { LoginRoot } from "../auth/login-root"
import { SignupRoot } from "../auth/signup-root"
import { Layout } from "../layout/layout"
import { NotFound } from "../not-found/not-found-page"
import { RouteWrapper, TAppRouteConfig } from "./route"
import { ERouteUrl } from "./routes-config"

const Academy = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Academy" */
      /* webpackMode: "lazy" */
      "../academy/academy-page"
    )
)

const ServiceArticlePage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Terms" */
      /* webpackMode: "lazy" */
      "../static-pages/service-article-page"
    )
)

// const BetForm = asyncComponent(() =>
//   import(
//     /* webpackChunkName: "BetForm" */
//     /* webpackMode: "lazy" */
//     "../tips/TipForm"
//   )
// )

const TipList = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TipsListPage" */
      /* webpackMode: "lazy" */
      "../tip/tip-list-page"
    )
)

const TipRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BetRoot" */
      /* webpackMode: "lazy" */
      "../tip/tip-page"
    )
)

const AccountLayout = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "AccountLayout" */
      /* webpackMode: "lazy" */
      "../account/account-layout"
    )
)

const AccountPersonal = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "AccountPersonal" */
      /* webpackMode: "lazy" */
      "../account/account-personal"
    )
)

const AccountNotifications = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "AccountNotifications" */
      /* webpackMode: "lazy" */
      "../account/account-notifications"
    )
)

// const AccountBookmakers = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "AccountBookmakers" */
//       /* webpackMode: "lazy" */
//       "../account-page/account-bookmakers"
//     )
// )

const ArticlesList = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "ArticlesList" */
      /* webpackMode: "lazy" */
      "../article/article-list-page"
    )
)

const BonusList = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BonusList" */
      /* webpackMode: "lazy" */
      "../bonus/bonus-list-page"
    )
)

const NewsPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "NewsPage" */
      /* webpackMode: "lazy" */
      "../news/news-page"
    )
)
const BlogPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BlogPage" */
      /* webpackMode: "lazy" */
      "../blog/blog-page"
    )
)
const BonusPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BonusPage" */
      /* webpackMode: "lazy" */
      "../bonus/bonus-page"
    )
)
const BookmakerRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BookmakerRoot" */
      /* webpackMode: "lazy" */
      "../bookmaker/bookmaker-page"
    )
)
const CasinoRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "CasinoRoot" */
      /* webpackMode: "lazy" */
      "../casino/casino-page"
    )
)
const Review = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Review" */
      /* webpackMode: "lazy" */
      "../review/review-page"
    )
)
const AllReviews = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "AllReviews" */
      /* webpackMode: "lazy" */
      "../review/review-all-bk-page"
    )
)
const Ratings = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Ratings" */
      /* webpackMode: "lazy" */
      "../rating/ratings-page"
    )
)
const RatingsCasino = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "RatingsCasino" */
      /* webpackMode: "lazy" */
      "../rating/casino-ratings-page"
    )
)
const LocalBusinessesPageRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "LocalBusinessesPageRoot" */
      /* webpackMode: "lazy" */
      "../local-business/local-business-list-page"
    )
)

const LocalBusinessPageRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "LocalBusinessPageRoot" */
      /* webpackMode: "lazy" */
      "../local-business/local-business-page"
    )
)

const QuizRoot = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "QuizRoot" */
      /* webpackMode: "lazy" */
      "../quiz/quiz-page"
    )
)

const TournamentPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TournamentPage" */
      /* webpackMode: "lazy" */
      "../tournament/tournament-page"
    )
)

const TeamPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TeamPage" */
      /* webpackMode: "lazy" */
      "../team/team-page"
    )
)

const TeamSquadPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TeamSquadPage" */
      /* webpackMode: "lazy" */
      "../team/team-squad-page"
    )
)

const HomePage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "HomePage" */
      /* webpackMode: "lazy" */
      "../home/home-page"
    )
)

const GlossaryListPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "GlossaryListPage" */
      /* webpackMode: "lazy" */
      "../glossary/glossary-list-page"
    )
)

const GlossaryPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "GlossaryPage" */
      /* webpackMode: "lazy" */
      "../glossary/glossary-page"
    )
)

const LayoutLanding = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "LayoutLanding" */
      /* webpackMode: "lazy" */
      "../layout/layout-landing"
    )
)

// const Search = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "Search" */
//       /* webpackMode: "lazy" */
//       "../search/search-page"
//     )
// )

const UserLayout = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserLayout" */
      /* webpackMode: "lazy" */
      "../user/user-layout"
    )
)

const UserArticleList = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserArticleList" */
      /* webpackMode: "lazy" */
      "../user/user-article-list"
    )
)

const UserTips = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserTips" */
      /* webpackMode: "lazy" */
      "../user/user-tips"
    )
)

const UserComments = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserComments" */
      /* webpackMode: "lazy" */
      "../user/user-comments"
    )
)

export const getRoutes = (region: string) => {
  const isRegionMX = region === "mx"

  const paths = {
    to_bk: isRegionMX ? ERouteUrl.bk_mx : ERouteUrl.bk,
    to_bonuses: isRegionMX ? ERouteUrl.bonuses_mx : ERouteUrl.bonuses,
    to_bonus: isRegionMX ? ERouteUrl.bonus_mx : ERouteUrl.bonus,
  }

  const routes: TAppRouteConfig[] = [
    {
      path: "/*",
      caseSensitive: true,
      element: <RouteWrapper />,
      component: Layout,
      dataKey: "layout",
      children: [
        {
          path: "/",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: HomePage,
          dataKey: "home",
        },
        {
          path: ERouteUrl.contact,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ServiceArticlePage,
          dataKey: "contacts",
        },
        {
          path: ERouteUrl.contact_mx,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ServiceArticlePage,
          dataKey: "contactsMx",
        },
        {
          path: ERouteUrl.terms,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ServiceArticlePage,
          dataKey: "terms",
        },
        {
          path: ERouteUrl.privacy,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ServiceArticlePage,
          dataKey: "privacy",
        },
        {
          path: ERouteUrl.academy,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: Academy,
          dataKey: "academy",
        },
        ...[
          ERouteUrl.tips,
          ERouteUrl.tips + "/:sport_slug",
          ERouteUrl.tips + "/:sport_slug/:tournament_slug",
        ].map((path) => ({
          path,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: TipList,
          dataKey: "tipFilterListPage",
        })),
        {
          path: ERouteUrl.tip + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: TipRoot,
          dataKey: "articleTipPage",
        },
        {
          path: ERouteUrl.signup,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: SignupRoot,
        },
        {
          path: ERouteUrl.login,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: LoginRoot,
        },
        {
          path: ERouteUrl.account + "/*",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: AccountLayout,
          dataKey: "accountLayout",
          children: [
            {
              path: "/",
              caseSensitive: true,
              element: <RouteWrapper />,
              component: AccountPersonal,
            },
            {
              path: "notifications", // ERouteUrl.notifications
              caseSensitive: true,
              element: <RouteWrapper />,
              component: AccountNotifications,
            },
            // {
            //   path: "bookmakers", // ERouteUrl.bookmakers,
            //   caseSensitive: true,
            //   element: <RouteWrapper />,
            //   component: AccountBookmakers,
            //   loadData: getLoadDataAccountBookmakers,
            //   dataKey: "account-bookmaker",
            // },
          ],
        },
        {
          path: ERouteUrl.user + "/:id/*",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: UserLayout,
          dataKey: "userLayout",
          children: [
            {
              path: "/",
              caseSensitive: true,
              element: <RouteWrapper />,
              component: UserArticleList,
              dataKey: "userBlogsPage",
            },
            {
              path: ERouteUrl.news,
              caseSensitive: true,
              element: <RouteWrapper />,
              component: UserArticleList,
              dataKey: "userNewsPage",
            },
            {
              path: ERouteUrl.tips,
              caseSensitive: true,
              element: <RouteWrapper />,
              component: UserTips,
              dataKey: "userTipsPage",
            },
            {
              path: ERouteUrl.comments,
              caseSensitive: true,
              element: <RouteWrapper />,
              component: UserComments,
              dataKey: "userCommentsPage",
            },
            {
              path: "*",
              element: <RouteWrapper />,
              component: NotFound,
              caseSensitive: true,
              dataKey: "notFound",
            },
          ],
        },
        {
          path: ERouteUrl.news,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ArticlesList,
          dataKey: "newsList",
        },
        ...[paths.to_bonuses, paths.to_bonuses + "/:bk_or_category_slug"].map(
          (path) => ({
            path,
            caseSensitive: true,
            element: <RouteWrapper />,
            component: BonusList,
            dataKey: "bonusListPage",
          })
        ),
        {
          path: ERouteUrl.blog,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: ArticlesList,
          dataKey: "blogList",
        },
        {
          path: ERouteUrl.news + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: NewsPage,
          dataKey: "newsPage",
        },
        {
          path: paths.to_bonus + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: BonusPage,
          dataKey: "articleBonusPage",
        },
        {
          path: ERouteUrl.blog + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: BlogPage,
          dataKey: "articleBlogPage",
        },
        {
          path: paths.to_bk + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: BookmakerRoot,
          dataKey: "bookmakerPage",
        },
        {
          path: ERouteUrl.casino + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: CasinoRoot,
          dataKey: "casinoPage",
        },
        {
          path: ERouteUrl.review + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: Review,
          dataKey: "bkReviewPage",
        },
        {
          path: ERouteUrl.review,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: AllReviews,
          dataKey: "allReviewsPage",
        },
        {
          path: paths.to_bk,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: Ratings,
          dataKey: "ratingPageBkAll",
        },
        {
          path: ERouteUrl.rating + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: Ratings,
          dataKey: "ratingPageBk",
        },
        {
          path: ERouteUrl.casino,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: RatingsCasino,
          dataKey: "ratingPageCasinoAll",
        },
        {
          path: ERouteUrl.pps,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: LocalBusinessesPageRoot,
          dataKey: "localBusinessListPage",
        },
        {
          path: ERouteUrl.pps + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: LocalBusinessPageRoot,
          dataKey: "localBusinessPage",
        },
        {
          path: ERouteUrl.quiz + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: QuizRoot,
          dataKey: "quizPage",
        },
        // {
        //   path: ERouteUrl.tournament + "/:slug",
        //   caseSensitive: true,
        //   element: <RouteWrapper />,
        //   component: TournamentPage,
        //   dataKey: "tournament",
        //   loadData: getTournamentPageLoader,
        // },
        {
          path: ERouteUrl.team + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: TeamPage,
          dataKey: "teamPage",
        },
        {
          path: ERouteUrl.team + "/:slug" + ERouteUrl.squad,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: TeamSquadPage,
          dataKey: "teamSquadPage",
        },
        {
          path: ERouteUrl.glossary,
          caseSensitive: true,
          element: <RouteWrapper />,
          component: GlossaryListPage,
          dataKey: "glossaryList",
        },
        {
          path: ERouteUrl.glossary + "/:slug",
          caseSensitive: true,
          element: <RouteWrapper />,
          component: GlossaryPage,
          dataKey: "glossary",
        },
        {
          path: "*",
          element: <RouteWrapper />,
          component: NotFound,
          caseSensitive: true,
          dataKey: "notFound",
        },
      ],
    },
  ]
  return routes
}
