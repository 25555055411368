import NoSsr from "@material-ui/core/NoSsr"
import clsx from "clsx"
import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router"
import { useDi } from "../App/di"
import { CountryTip } from "../country/country-tip"
import { Footer } from "../footer/footer-root"
import { Header } from "../header/header-root"
import { NotFound } from "../not-found/not-found-page"
import { TLayout } from "../payload"
import { DataRoutes, TRouteComponentProps } from "../routes/route"
import { ERouteUrl } from "../routes/routes-config"
import { MainWidget } from "../widget/main-widget"
import { WidgetPopup } from "../widget/widget-popup"
import { LayoutLazyComponents } from "./nossr"

export type TLayoutCs =
  | "wrapper"
  | "main"
  | "pageWrapper"
  | "inner"
  | "grey"
  | "headerOffset"
export const Layout: FC<TRouteComponentProps<TLayout>> = (props) => {
  const location = useLocation()
  const {
    story,
    cs: { Layout: c },
    region,
  } = useDi()

  const path_to_bonuses =
    region.name === "mx" ? ERouteUrl.bonuses_mx : ERouteUrl.bonuses

  const is_grey_bg =
    location.pathname === "/" ||
    location.pathname === ERouteUrl.blog ||
    location.pathname === ERouteUrl.news ||
    location.pathname.startsWith(path_to_bonuses) ||
    location.pathname.startsWith(ERouteUrl.tips) ||
    location.pathname.startsWith(ERouteUrl.user)

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle={props.seo_title}>
        <meta name="description" content={props.seo_description} />
        <meta property="og:image" content="/img/home-hero.jpg" />
      </Helmet>
      <div className={clsx(c.wrapper, is_grey_bg && c.grey)}>
        <CountryTip />
        <Header layout={props} />
        <div className={c.headerOffset} id="catfish-trigger" />
        {props.main_widget && <MainWidget widget={props.main_widget} />}
        <WidgetPopup />
        <main className={c.main}>
          <div className={c.pageWrapper}>
            <div className={c.inner}>
              {story.state.statusCode === 404 ? (
                <NotFound />
              ) : (
                <DataRoutes routes={props.route.children!} story={story} />
              )}
            </div>
            <Footer layout={props} />
          </div>
        </main>
      </div>
      <NoSsr>
        <LayoutLazyComponents />
      </NoSsr>
    </>
  )
}
